import { OptionType } from "../../../../components/select/Select"
import clsx from "clsx"
import { ModelSelectionCreativelyIcon, ModelSelectionRusIcon, ModelSelectionSeriouslyIcon, ModelSelectionUndefined } from "./model-selection-icons"
import { UndefinedModel } from "./model-undefined"
// import { PopupMessage } from "../../../../components/popup-message/popup-message"
// import { useState } from "react"

import s from "./model-selection.module.css"
import { currentModelsIds } from "./models"
import { QuestionsBlockMiniHeader } from "../../../../components/questions-mini-header/questions-mini-header"

type PropsType = {
    selectModel: string
    models: Array<OptionType>
    setSelectModel: (newModel: string) => void
}

export const ModelSelectionQuestionsArea = ({selectModel, models, setSelectModel}: PropsType) => {

    const popupMsgText: Array<string> = [
        "Здесь меняется модель нейронки!",
        "На одной и той же фактуре - результаты будут принципиально разные!",
        "Мы вынесли в название ключевую суть «стиля» модели, но лучше попробуйте сами и выберете наиболее интересный вам вариант)",
    ]
    // const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

    const onModelClickHandler = (modelId: string) => {
        setSelectModel(modelId)
    }


    return (<>{models.length > 0 &&
        <div className={s.model_selection_wrapper} id={"model_selection_id"}>

            <QuestionsBlockMiniHeader text={"Как пишем?"} popupText={popupMsgText} />
            {/* <div className={s.model_selection_top_line}>
                <div>Как пишем?</div>
                <div
                    onMouseMove={() => setIsShowPopup(true)}
                    onMouseLeave={() => setIsShowPopup(false)}
                    className={s.model_selection_top_line_question_icon}
                >
                    <QuestionIcon isSelected={isShowPopup}/>
                    {isShowPopup && <PopupMessage msgText={popupMsgText} />}
                </div>
            </div> */}

            <div className={s.model_selection_grid_div}>
                {models.map(m => {
                    const currentModelImg = m.value === currentModelsIds.seriousModelId
                        ?   <ModelSelectionSeriouslyIcon isSelected={selectModel === m.value}/>
                        :   m.value === currentModelsIds.creativeModelId
                            ? <ModelSelectionCreativelyIcon isSelected={selectModel === m.value} />
                            : m.value === currentModelsIds.rusModelId && <ModelSelectionRusIcon isSelected={selectModel === m.value} />
                    const modelName: string = m.value === currentModelsIds.seriousModelId
                        ? "Gemini"
                        : m.value === currentModelsIds.creativeModelId
                            ? "OpenAI"
                            : m.value === currentModelsIds.rusModelId ? "YaGPT" : ""

                    return (
                        <div 
                            key={m.value}
                            className={clsx(s.model_selection_field, m.value === selectModel ? s.selected : s.unselected)}
                            onClick={() => onModelClickHandler(m.value)}
                        >
                            {currentModelImg}
                            <div className={s.model_selection_label_1}>
                                {m.title}
                            </div>
                            <div className={s.model_selection_label_2}>
                                {modelName}
                            </div>
                        </div>
                    )
                })}

                <UndefinedModel />

            </div>

        </div>
    }</>)
}