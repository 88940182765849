import { useSelector } from "react-redux";
import {
  CategoryTypeGpt,
  // ChangingOneQuestionType,
  QuestionType,
} from "../../../store/features/questionTypes";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import OneQuestion from "./oneQuestion/oneQuestion";
import showIcon from "../../../assets/icons/contentPage/folding_show_icon.svg";
import hideIcon from "../../../assets/icons/contentPage/folding_hide_icon.svg";
import QuestionsButtons from "./questionsButtons/questionsButtons";
import { changeIsContentQuestionsShow } from "../../../store/features/parametrSlice";
import {
  ResizeType,
  useResize,
} from "../../../common/hooks/use-resize/useResize";
import { Loader } from "../../../components/loader/loader";
// import { Button } from "../../../components/button/button";
// import send from "../../../assets/icons/send.svg";
// import { toast } from "react-toastify";
// import { addWhichCategoryGptLoadingAC } from "../../../store/features/answerSlice";
// import {
//   postReceiveGptThunkV2,
//   sendVisionGPTThunk,
// } from "../../../store/features/answerThunk";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UploadImage } from "../../../components/upload-image/upload-image";
import { appActions } from "../../../store/features/app/app-slice";
import { appSelectors } from "../../../store/features/app/app-selectors";
// import { OptionType, Select } from "../../../components/select/Select";
// import { Typography } from "../../../components/typography/Typography";
// import { TypographyVariant } from "../../../store/storeConst/enums";
import { ModelSelectionQuestionsArea } from "./model-selection/model-selection-area";
import { QuestionsBlockMiniHeader } from "../../../components/questions-mini-header/questions-mini-header";

import s from "./questions.module.css";

type QuestionsPropsType = {
  type_gpt: CategoryTypeGpt;
  mobileSwitch?: () => void;
};
const Questions = ({ mobileSwitch, type_gpt }: QuestionsPropsType) => {
  const dispatch = useAppDispatch();
  const devWidth: ResizeType = useResize();
  const isQuestionsShown = useAppSelector(
    (state) => state.parametr.contentBlockQuestionsShow
  );

  const { tourActive } = useAppSelector(appSelectors.getOnboarding());

  const [imageForVision, setImage] = useState<File | null>(null);

  const showHideIcon: any =
    isQuestionsShown && devWidth.width > 620 ? hideIcon : showIcon;
  const questions = useAppSelector((state) => state.question.questions);
  const models = useAppSelector((state) => state.question.models);
  const [selectModel, setSelectModel] = useState(
    models.length > 0 ? models[0].value : ""
  );
  
  useEffect(() => {
    models.length > 0 && setSelectModel(models[0].value);
  }, [models]);

  const params = useParams();
  const currentCategoryId = params.categoryId || "";

  const isQuestionsFulfilled: boolean = questions && questions.length > 0;
  const isQuestionsLoading: boolean = useSelector(
    (state: RootState) => state.question.loadingVars.isQuestionsLoading
  );

  const qeustionsPopupMsg: Array<string> = [
    'В этом блоке нужно предоставить нейросети информацию о будущем тексте. Как говорят журналисты и копирайтеры, "дать фактуру". И это не сложно!',
    'Просто отвечайте на наши наводящие вопросы, и чем больше информации вы дадите, тем более качественным и "кастомизированным" будет результат!'
  ]
  // const whichCategoryGptAnswerLoading: Array<string> = useSelector(
  //   (state: RootState) => state.answer.loadingVars.whichCategoryGptAnswerLoading
  // );
  // const isThisCatAnswerLoading: boolean = //isGptAnswerLoading &&
  //   whichCategoryGptAnswerLoading.some((el) => currentCategoryId === el);

  const onShowHideClickHandler = () => {
    dispatch(changeIsContentQuestionsShow(!isQuestionsShown));
  };

  // const onSendToGPRClickHandler = () => {
  //   const condition: boolean = questions.some(
  //     (q) => q.isRequired && (q.answer === "" || q.answer === null)
  //   );
  //   if (condition) {
  //     toast.error("Не все обязательные поля заполнены");
  //   } else if (type_gpt === "gpt_vision" && !imageForVision) {
  //     toast.error("Вставьте изображение");
  //   } else {
  //     dispatch(addWhichCategoryGptLoadingAC(currentCategoryId));

  //     const userAnswers: Array<ChangingOneQuestionType> = [...questions].map(
  //       (q) => {
  //         return { question_id: q.id, answer: q.answer };
  //       }
  //     );
  //     mobileSwitch && mobileSwitch();
  //     type_gpt === "gpt_vision"
  //       ? dispatch(
  //           sendVisionGPTThunk({
  //             image: imageForVision!,
  //             request_data: {
  //               category_id: currentCategoryId,
  //               answers: userAnswers,
  //             },
  //           })
  //         )
  //       : dispatch(
  //           postReceiveGptThunkV2({
  //             question_answer: userAnswers,
  //             category_id: {
  //               categoryId: currentCategoryId,
  //               model_gpt_id: selectModel && selectModel?.length > 0 ? selectModel : "",
  //             },
  //           })
  //         );
  //   }
  // };

  useEffect(() => {
    if (isQuestionsShown && tourActive && !isQuestionsLoading) {
      // Запускаем онбординг если он запущен при переходе на страницу. {
      dispatch(appActions.setOnboarding({ run: true, stepIndex: 13 }));
    }
  }, [isQuestionsShown, tourActive, isQuestionsLoading, dispatch]);
  return (
    <div className={s.contentWrapper}>
      {isQuestionsLoading ? (
        <Loader position={"container"} />
      ) : (
        <>
          <div className={s.titleDiv}>
            {isQuestionsShown && <h3>Заполнение данных</h3>}

            {devWidth.width > 620 && (
              <div className={s.showHideDiv}>
                <img
                  alt=""
                  src={showHideIcon}
                  onClick={onShowHideClickHandler}
                  className={s.showHideImg}
                />
              </div>
            )}
          </div>
          {isQuestionsFulfilled && isQuestionsShown ? (
            <>

              <ModelSelectionQuestionsArea 
                selectModel={selectModel} 
                models={models} 
                setSelectModel={(newModel: string) => setSelectModel(newModel)}
              />
              
              <div id={"questionsDiv"} className={s.questionsDiv}>

                <QuestionsBlockMiniHeader text={"Дайте фактуру!"} popupText={qeustionsPopupMsg} />
                
                {/* {models.length > 0 && (
                  <div className={s.selectWrapper}>
                    <Typography variant={TypographyVariant.Body2}>
                      Как пишем?
                    </Typography>
                    <Select
                      defaultValue={models.length > 0 ? models[0].value : ""}
                      value={selectModel}
                      fullWidth
                      options={models}
                      onValueChange={(value) => setSelectModel(value)}
                    />
                  </div>
                )} */}
                

                {questions.map((q: QuestionType) => {
                  return <OneQuestion key={q.question} question={q} />;
                })}
                {type_gpt === "gpt_vision" && (
                  <UploadImage setImage={setImage} />
                )}
                {/* <Button
                  id={"generateTextButton"}
                  className={s.button}
                  onClick={onSendToGPRClickHandler}
                  img={send}
                  disabled={isThisCatAnswerLoading}
                >
                  Генерировать текст
                </Button> */}
              </div>
              <QuestionsButtons 
                type_gpt={type_gpt} 
                currentCategoryId={currentCategoryId} 
                imageForVision={imageForVision} 
                selectModel={selectModel} 
              />
            </>
          ) : (
            <div className={s.under_construction_div}>
              {isQuestionsShown && (
                <>
                  <h3>Данный раздел </h3>
                  <h3>в стадии разработки...</h3>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Questions;
