import axios, { AxiosError } from "axios";
import { Paths } from "../router/paths";
import { authAPI } from "./auth-api/auth-api";
import { Tokens } from "../../store/storeConst/enums";
import { apiErrorCodes } from "./errorCodes";

// const baseServerIP = "https://app.aipr.pro";
const baseServerIP = "https://mainappbackend.myprojectexample738.ru";

export const instance = axios.create({
  baseURL: baseServerIP,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `${localStorage.getItem(Tokens.access)}`;
  }
  return config;
});

type ResponseErrorType = {
  message: string;
  status: string;
};
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error: AxiosError<ResponseErrorType>) => {
    if (error.response?.data.message === "Refresh token has expired") {
      return;
    }
    if (error.response?.data.message === "Could not validate credentials") {
      try {
        const refresh_token = localStorage.getItem(Tokens.refresh);
        const res = await authAPI.refreshToken(refresh_token!);
        const access_token = res.data.access_token;

        localStorage.setItem(Tokens.access, access_token);

        const originalRequest = error.config;
        originalRequest!.headers.Authorization = `${access_token}`;

        return instance(originalRequest!);
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (error.response?.status === apiErrorCodes.serverErrorCode) {
      window.location.href = Paths.serverIsAvailable;
    }
    return Promise.reject(error);
  }
);
